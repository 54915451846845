.share {
  padding: 12px;
  display: flex;

  .icon {
    fill: var(--white-color);
    stroke: var(--grey-darker-color);
    transition: all 0.2s ease;

    path {
      stroke: var(--grey-darker-color);
    }

    circle {
      stroke: var(--grey-darker-color);
      transition: all 0.2s ease;
    }
  }

  &:hover {
    .icon {
      circle {
        fill: var(--grey-darker-color);
      }
    }
  }
}
